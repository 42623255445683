import {
  CREATE_PROXY_USER_REQUEST,
  CREATE_PROXY_USER_SUCCESS,
  CREATE_PROXY_USER_FAILURE,
  UPDATE_PROXY_REQUEST,
  UPDATE_PROXY_SUCCESS,
  UPDATE_PROXY_FAILURE,
  BATCH_INVITE_PROXY_USER_REQUEST,
  BATCH_INVITE_PROXY_USER_SUCCESS,
  BATCH_INVITE_PROXY_USER_FAILURE,
  REINVITE_PROXY_USER_REQUEST,
  REINVITE_PROXY_USER_SUCCESS,
  REINVITE_PROXY_USER_FAILURE,
  ARCHIVE_PROXY_USER_REQUEST,
  ARCHIVE_PROXY_USER_SUCCESS,
  ARCHIVE_PROXY_USER_FAILURE,
  CREATE_PROXY_CONFIGURATION_REQUEST,
  CREATE_PROXY_CONFIGURATION_SUCCESS,
  CREATE_PROXY_CONFIGURATION_FAILURE,
  UPDATE_PROXY_CONFIGURATION_REQUEST,
  UPDATE_PROXY_CONFIGURATION_SUCCESS,
  UPDATE_PROXY_CONFIGURATION_FAILURE,
  FETCH_PROXY_CONFIGURATION_REQUEST,
  FETCH_PROXY_CONFIGURATION_SUCCESS,
  FETCH_PROXY_CONFIGURATION_FAILURE,
  FETCH_PROXY_USERS_REQUEST,
  FETCH_PROXY_USERS_SUCCESS,
  FETCH_PROXY_USERS_FAILURE,
  FETCH_TRAVELER_PROXY_USERS_REQUEST,
  FETCH_TRAVELER_PROXY_USERS_SUCCESS,
  FETCH_TRAVELER_PROXY_USERS_FAILURE,
  ADD_EXISTING_PROXY_REQUEST,
  ADD_EXISTING_PROXY_SUCCESS,
  ADD_EXISTING_PROXY_FAILURE,
  REMOVE_PROXY_ACCESS_REQUEST,
  REMOVE_PROXY_ACCESS_SUCCESS,
  REMOVE_PROXY_ACCESS_FAILURE,
  AUTHORIZED_TRAVELERS_REQUEST,
  AUTHORIZED_TRAVELERS_SUCCESS,
  AUTHORIZED_TRAVELERS_FAILURE,
  FETCH_PROXY_PLANS_REQUEST,
  FETCH_PROXY_PLANS_SUCCESS,
  FETCH_PROXY_PLANS_FAILURE,
} from '../actions/types';

const initialState = {
  proxyUsers: [],
  travelerProxyUserData: null,
  proxyConfiguration: null,
  loading: false,
  addExistingProxyLoading: false,
  addExistingProxyError: false,
  error: null,
  successMessage: null,
  removeProxyLoading: false,
  removeProxyError: null,
  authorizedTravelers: [],
  authorizedTravelersLoading: false,
  authorizedTravelersError: null,
  proxyPlans: [],
  proxyPlansLoading: false,
  proxyPlansError: null,
};

const proxyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROXY_USER_REQUEST:
    case UPDATE_PROXY_REQUEST:
    case BATCH_INVITE_PROXY_USER_REQUEST:
    case REINVITE_PROXY_USER_REQUEST:
    case ARCHIVE_PROXY_USER_REQUEST:
    case CREATE_PROXY_CONFIGURATION_REQUEST:
    case UPDATE_PROXY_CONFIGURATION_REQUEST:
    case FETCH_PROXY_CONFIGURATION_REQUEST:
    case FETCH_PROXY_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        successMessage: null,
      };
    case CREATE_PROXY_USER_SUCCESS:
    case UPDATE_PROXY_SUCCESS:
    case BATCH_INVITE_PROXY_USER_SUCCESS:
    case REINVITE_PROXY_USER_SUCCESS:
    case ARCHIVE_PROXY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: 'Operation successful',
        error: null,
      };
    case CREATE_PROXY_CONFIGURATION_SUCCESS:
    case UPDATE_PROXY_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        proxyConfiguration: action.payload,
        successMessage: 'Operation successful',
        error: null,
      };

    case FETCH_PROXY_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        proxyUsers: action.payload,
        successMessage: 'Proxy users fetched successfully',
        error: null,
      };

    case FETCH_PROXY_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        proxyConfiguration: action.payload,
        successMessage: 'Proxy configuration fetched successfully',
        error: null,
      };

    case CREATE_PROXY_USER_FAILURE:
    case UPDATE_PROXY_FAILURE:
    case BATCH_INVITE_PROXY_USER_FAILURE:
    case REINVITE_PROXY_USER_FAILURE:
    case ARCHIVE_PROXY_USER_FAILURE:
    case CREATE_PROXY_CONFIGURATION_FAILURE:
    case UPDATE_PROXY_CONFIGURATION_FAILURE:
    case FETCH_PROXY_CONFIGURATION_FAILURE:
    case FETCH_PROXY_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_TRAVELER_PROXY_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        travelerProxyUsers: [],
      };

    case FETCH_TRAVELER_PROXY_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        travelerProxyUserData: action.payload,
      };

    case FETCH_TRAVELER_PROXY_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_EXISTING_PROXY_REQUEST:
      return {
        ...state,
        addExistingProxyLoading: true,
        addExistingProxyError: null,
      };
    case ADD_EXISTING_PROXY_SUCCESS:
      return {
        ...state,
        addExistingProxyLoading: false,
      };
    case ADD_EXISTING_PROXY_FAILURE:
      return {
        ...state,
        addExistingProxyLoading: false,
        addExistingProxyError: action.payload,
      };

    case REMOVE_PROXY_ACCESS_REQUEST:
      return {
        ...state,
        removeProxyLoading: true,
        removeProxyError: null,
      };

    case REMOVE_PROXY_ACCESS_SUCCESS:
      return {
        ...state,
        removeProxyLoading: false,
      };

    case REMOVE_PROXY_ACCESS_FAILURE:
      return {
        ...state,
        removeProxyLoading: false,
        removeProxyError: action.payload,
      };

    case AUTHORIZED_TRAVELERS_REQUEST:
      return {
        ...state,
        authorizedTravelersLoading: true,
        authorizedTravelersError: null,
      };

    case AUTHORIZED_TRAVELERS_SUCCESS:
      return {
        ...state,
        authorizedTravelersLoading: false,
        authorizedTravelers: action.payload,
      };

    case AUTHORIZED_TRAVELERS_FAILURE:
      return {
        ...state,
        authorizedTravelersLoading: false,
        authorizedTravelersError: action.payload,
      };

    case FETCH_PROXY_PLANS_REQUEST:
      return {
        ...state,
        proxyPlansLoading: true,
        proxyPlansError: null,
      };

    case FETCH_PROXY_PLANS_SUCCESS:
      return {
        ...state,
        proxyPlansLoading: false,
        proxyPlans: action.payload,
      };

    case FETCH_PROXY_PLANS_FAILURE:
      return {
        ...state,
        proxyPlansLoading: false,
        proxyPlansError: action.payload,
      };

    default:
      return state;
  }
};

export default proxyReducer;
