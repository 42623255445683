import moment from 'moment-timezone';
import _cloneDeep from 'lodash/cloneDeep';

import {
  FORMS_LIST_CLEAR,
  FORMS_LIST_FAILURE,
  FORMS_LIST_REQUEST,
  FORMS_LIST_SUCCESS,
  CREATE_FORM_REQUEST,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_CLEAR,
  CREATE_FORM_FAILURE,
  FETCH_QUESTION_TYPES_CLEAR,
  FETCH_QUESTION_TYPES_REQUEST,
  FETCH_QUESTION_TYPES_FAILURE,
  FETCH_QUESTION_TYPES_SUCCESS,
  FETCH_FORM_CLEAR,
  FETCH_FORM_SUCCESS,
  FETCH_FORM_FAILURE,
  UPDATE_FORM_ON_CLIENT,
  FETCH_FORM_REQUEST,
  UPDATE_FORM_CLEAR,
  UPDATE_FORM_REQUEST,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_FAILURE,
  UPDATE_FORM_STATUS_CLEAR,
  UPDATE_FORM_STATUS_REQUEST,
  UPDATE_FORM_STATUS_SUCCESS,
  UPDATE_FORM_STATUS_FAILURE,
  FAKE_FORM_UPDATE,
  UPDATE_BUILDER_FORM_STATUS_SUCCESS,
  UPDATE_QUESTION_CLEAR,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  DUPLICATE_FORM_CLEAR,
  DUPLICATE_FORM_REQUEST,
  DUPLICATE_FORM_SUCCESS,
  DUPLICATE_FORM_FAILURE,
  DELETE_FORM_CLEAR,
  DELETE_FORM_REQUEST,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_FAILURE,
  UPDATE_LAYOUT_CLEAR,
  UPDATE_LAYOUT_REQUEST,
  UPDATE_LAYOUT_SUCCESS,
  UPDATE_LAYOUT_FAILURE,
  DELETE_QUESTION_CLEAR,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  UPDATE_FORM_NAME_CLEAR,
  UPDATE_FORM_NAME_REQUEST,
  UPDATE_FORM_NAME_SUCCESS,
  UPDATE_FORM_NAME_FAILURE,
  FETCH_TRAVELER_SUBMISSION_REQUEST,
  FETCH_TRAVELER_SUBMISSION_SUCCESS,
  FETCH_TRAVELER_SUBMISSION_FAILURE,
  FETCH_TRAVELER_SUBMISSION_CLEAR,
  PATCH_TRAVELER_FORM_REQUEST,
  PATCH_TRAVELER_FORM_SUCCESS,
  PATCH_TRAVELER_FORM_FAILURE,
  PATCH_TRAVELER_FORM_CLEAR,
  FETCH_SUBMISSION_STATUSES_REQUEST,
  FETCH_SUBMISSION_STATUSES_SUCCESS,
  FETCH_SUBMISSION_STATUSES_FAILURE,
  FETCH_SUBMISSION_STATUSES_CLEAR,
  FETCH_VISITOR_SUBMISSION_REQUEST,
  FETCH_VISITOR_SUBMISSION_SUCCESS,
  FETCH_VISITOR_SUBMISSION_FAILURE,
  FETCH_VISITOR_SUBMISSION_CLEAR,
  FETCH_TRAVELER_ALTERNATE_INFO_REQUEST,
  FETCH_TRAVELER_ALTERNATE_INFO_SUCCESS,
  FETCH_TRAVELER_ALTERNATE_INFO_FAILURE,
  FETCH_TRAVELER_ALTERNATE_INFO_CLEAR,
  POST_SEND_RECOMMENDATION_MAILER_REQUEST,
  POST_SEND_RECOMMENDATION_MAILER_SUCCESS,
  POST_SEND_RECOMMENDATION_MAILER_FAILURE,
  POST_SEND_RECOMMENDATION_MAILER_CLEAR,
  CANCEL_SEND_RECOMMENDATION_MAILER_REQUEST,
  CANCEL_SEND_RECOMMENDATION_MAILER_SUCCESS,
  CANCEL_SEND_RECOMMENDATION_MAILER_FAILURE,
  CANCEL_SEND_RECOMMENDATION_MAILER_CLEAR,
  RESEND_RECOMMENDATION_MAILER_REQUEST,
  RESEND_RECOMMENDATION_MAILER_SUCCESS,
  RESEND_RECOMMENDATION_MAILER_FAILURE,
  RESEND_RECOMMENDATION_MAILER_CLEAR,
  PATCH_VISITOR_SUBMISSION_REQUEST,
  PATCH_VISITOR_SUBMISSION_SUCCESS,
  PATCH_VISITOR_SUBMISSION_FAILURE,
  PATCH_VISITOR_SUBMISSION_CLEAR,
  UPDATE_FORM_SUBMISSION_RESPONSE,
  UPLOAD_FILE_ADD_REQUEST,
  UPLOAD_FILE_ADD_SUCCESS,
  UPLOAD_FILE_ADD_FAILURE,
  UPLOAD_FILE_ADD_CLEAR,
  UPLOAD_FILE_DELETE_REQUEST,
  UPLOAD_FILE_DELETE_SUCCESS,
  UPLOAD_FILE_DELETE_FAILURE,
  UPLOAD_FILE_DELETE_CLEAR,
  PAYMENT_STATUS_OPTIONS_REQUEST,
  PAYMENT_STATUS_OPTIONS_SUCCESS,
  PAYMENT_STATUS_OPTIONS_FAILURE,
  PAYMENT_STATUS_OPTIONS_CLEAR,
  PAYMENT_STATUS_FLYWIRE_UPDATE_ADMIN,
  PAYMENT_STATUS_FLYWIRE_UPDATE,
  UPDATE_ALL_PAYMENTS_STATUS_ADMIN,
  FETCH_TRAVELER_INFO_FIELDS_REQUEST,
  FETCH_TRAVELER_INFO_SUCCESS,
  FETCH_TRAVELER_INFO_FIELDS_FAILURE,
  FETCH_TRAVELER_INFO_FIELDS_CLEAR,
  SET_TRANSFER_APPLICATION_ERROR,
  SET_TRANSFER_APPLICATION_SUCCESS,
  ADMIN_ADD_FORMS_REQUEST,
  ADMIN_ADD_FORMS_SUCCESS,
  ADMIN_ADD_FORMS_FAILURE,
  ADMIN_ADD_FORMS_CLEAR,
  DELETE_TRAVELER_APPLICATION_CLEAR,
  DELETE_TRAVELER_APPLICATION_REQUEST,
  DELETE_TRAVELER_APPLICATION_FAILURE,
  DELETE_TRAVELER_APPLICATION_SUCCESS,
  ADD_FORMS_TO_TRAVELER_PROFILE_CLEAR,
  ADD_FORMS_TO_TRAVELER_PROFILE_REQUEST,
  ADD_FORMS_TO_TRAVELER_PROFILE_FAILURE,
  ADD_FORMS_TO_TRAVELER_PROFILE_SUCCESS,
  ADD_APPLICATION_TO_TRAVELER_PROFILE_REQUEST,
  ADD_APPLICATION_TO_TRAVELER_PROFILE_FAILURE,
  ADD_APPLICATION_TO_TRAVELER_PROFILE_SUCCESS,
  ADD_APPLICATION_TO_TRAVELER_PROFILE_CLEAR,
  GET_AVAILABLE_FORMS_CLEAR,
  GET_AVAILABLE_FORMS_REQUEST,
  GET_AVAILABLE_FORMS_FAILURE,
  GET_AVAILABLE_FORMS_SUCCESS,
  FETCH_AVAILABLE_APPLICATIONS_REQUEST,
  FETCH_AVAILABLE_APPLICATIONS_CLEAR,
  FETCH_AVAILABLE_APPLICATIONS_FAILURE,
  FETCH_AVAILABLE_APPLICATIONS_SUCCESS,
  FETCH_AVAILABLE_ALTERNATES_REQUEST,
  FETCH_AVAILABLE_ALTERNATES_CLEAR,
  FETCH_AVAILABLE_ALTERNATES_FAILURE,
  FETCH_AVAILABLE_ALTERNATES_SUCCESS,
  SET_ALTERNATE_SAVE_ERROR,
  REQUEST_TO_WITHDRAW_REQUEST,
  REQUEST_TO_WITHDRAW_SUCCESS,
  REQUEST_TO_WITHDRAW_FAILURE,
  RESPOND_REQUEST_TO_WITHDRAW_REQUEST,
  RESPOND_REQUEST_TO_WITHDRAW_SUCCESS,
  RESPOND_REQUEST_TO_WITHDRAW_FAILURE,
  FETCH_AVAILABLE_TAG_REQUEST,
  FETCH_AVAILABLE_TAG_SUCCESS,
  FETCH_AVAILABLE_TAG_FAILURE,
  FETCH_AVAILABLE_TAG_CLEAR,
  FETCH_TAG_COLORS_REQUEST,
  FETCH_TAG_COLORS_SUCCESS,
  FETCH_TAG_COLORS_FAILURE,
  FETCH_TAG_COLORS_CLEAR,
  ATTACH_TAG_REQUEST,
  ATTACH_TAG_SUCCESS,
  ATTACH_TAG_FAILURE,
  ATTACH_TAG_CLEAR,
  CREATE_SUBMISSION_TAG_REQUEST,
  CREATE_SUBMISSION_TAG_SUCCESS,
  CREATE_SUBMISSION_TAG_FAILURE,
  CREATE_SUBMISSION_TAG_CLEAR,
  UPDATE_SUBMISSION_TAG_REQUEST,
  UPDATE_SUBMISSION_TAG_SUCCESS,
  UPDATE_SUBMISSION_TAG_FAILURE,
  UPDATE_SUBMISSION_TAG_CLEAR,
  DELETE_SUBMISSION_TAG_REQUEST,
  DELETE_SUBMISSION_TAG_SUCCESS,
  DELETE_SUBMISSION_TAG_FAILURE,
  GET_TOKENS_REQUEST,
  GET_TOKENS_FAILURE,
  GET_TOKENS_SUCCESS,
  BATCH_ZIP_DOWNLOAD_REQUEST,
  BATCH_ZIP_DOWNLOAD_SUCCESS,
  BATCH_ZIP_DOWNLOAD_FAILURE,
  FETCH_WITHDRAW_DEFFERED_REASON,
  ADMIN_ADD_PLAN_FORM_TO_PLAN_REQUEST,
  ADMIN_ADD_PLAN_FORM_TO_PLAN_SUCCESS,
  ADMIN_ADD_PLAN_FORM_TO_PLAN_FAILURE,
  ADMIN_UPDATE_PLAN_FORM_TO_PLAN_REQUEST,
  ADMIN_UPDATE_PLAN_FORM_TO_PLAN_SUCCESS,
  ADMIN_UPDATE_PLAN_FORM_TO_PLAN_FAILURE,
  ADMIN_DELETE_PLAN_FORM_TO_PLAN_REQUEST,
  ADMIN_DELETE_PLAN_FORM_TO_PLAN_SUCCESS,
  ADMIN_DELETE_PLAN_FORM_TO_PLAN_FAILURE,
  BATCH_MANUAL_DATA_SYNC_REQUEST,
  BATCH_MANUAL_DATA_SYNC_SUCCESS,
  BATCH_MANUAL_DATA_SYNC_FAILURE,
  ADD_PLAN_FORMS_TO_TRAVELERS_REQUEST,
  ADD_PLAN_FORMS_TO_TRAVELERS_SUCCESS,
  ADD_PLAN_FORMS_TO_TRAVELERS_FAILURE,
  FETCH_RECOMMENDATION_SUBMISSIONS_REQUEST,
  FETCH_RECOMMENDATION_SUBMISSIONS_SUCCESS,
  FETCH_RECOMMENDATION_SUBMISSIONS_FAILURE,
  FETCH_RECOMMENDATION_SUBMISSIONS_CLEAR,
} from '../actions/types';

const initialState = {
  forms: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  createForm: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  questionTypes: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  fetchForm: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  updateForm: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  updateFormName: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  updateFormStatus: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  updateQuestion: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  duplicateForm: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  deleteForm: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  updateLayout: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  deleteQuestion: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  travelerSubmission: {
    data: null,
    requestToWithdrawData: null,
    submissionWithdrawalRequests: [],
    loading: false,
    error: false,
    message: null,
  },
  recommendationSubmissions: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  patchTravelerForm: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  submission_statuses: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  visitorRecommendationSubmission: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  patchVisitorRecommendationSubmission: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  alternateInfo: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  sendRecommendationMailer: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  cancelRecommendationMailer: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  resendRecommendationMailer: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  uploadFileAdd: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  uploadFileDelete: {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  paymentStatusOptions: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  travelerInfoFields: {
    data: null,
    custom_fields: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  adminAddForms: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  deleteTravelerApplication: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  addFormsProfile: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  addApplicationProfile: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  availableForms: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  transferApplication: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  availableAlternates: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  availableApplications: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },
  availableTags: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },

  deleteTag: {
    loading: false,
    error: '',
  },

  saveAltError: {
    value: false,
  },

  requestToWithdraw: {
    loading: false,
    error: '',
  },

  respondRequestToWithdraw: {
    loading: false,
    error: '',
  },
  submissionTag: {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
  },

  token: {
    data: [],
    loading: false,
    error: '',
  },

  batchZipDownload: {
    loading: false,
    data: null,
    error: '',
  },

  batchManualDataSync: {
    loading: false,
    data: null,
    error: '',
  },

  withdrawReason: {
    loading: false,
    data: null,
    error: '',
  },

  addPlanFormsToPlans: {
    loading: false,
    data: null,
    error: '',
  },

  updatePlanFormsToPlans: {
    loading: false,
    data: null,
    error: '',
  },

  deletePlanFormsToPlans: {
    loading: false,
    data: null,
    error: '',
  },

  addPlanFormsToTravelers: {
    loading: false,
    data: null,
    error: '',
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FORMS_LIST_CLEAR:
      return {
        ...state,
        forms: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FORMS_LIST_REQUEST:
      return {
        ...state,
        forms: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FORMS_LIST_SUCCESS:
      let formsData = [];
      if (action.payload && action.payload.data) {
        action.payload.data.forEach(item => {
          formsData.push({
            created_at: moment(new Date(item.attributes.created_at)).format('MMM D, YYYY'),
            created_by_avatar: item.attributes.created_by.avatar_url,
            created_by_email: item.attributes.created_by.email,
            created_by_first: item.attributes.created_by.first_name,
            created_by_last: item.attributes.created_by.last_name,
            default: item.attributes.default,
            id: item.id,
            name: item.attributes.name,
            status: item.attributes.status,
            template_type: action.payload.template_type,
            updated_at: moment(new Date(item.attributes.updated_at)).format('MMM D, YYYY'),
          });
        });
      }

      return {
        ...state,
        forms: {
          data: formsData,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case SET_ALTERNATE_SAVE_ERROR:
      return {
        ...state,
        saveAltError: {
          value: action.payload,
        },
      };
    case FORMS_LIST_FAILURE:
      return {
        ...state,
        forms: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case CREATE_FORM_CLEAR:
      return {
        ...state,
        createForm: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case CREATE_FORM_REQUEST:
      return {
        ...state,
        createForm: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case CREATE_FORM_SUCCESS:
      return {
        ...state,
        createForm: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case CREATE_FORM_FAILURE:
      return {
        ...state,
        createForm: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case FETCH_QUESTION_TYPES_CLEAR:
      return {
        ...state,
        questionTypes: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_QUESTION_TYPES_REQUEST:
      return {
        ...state,
        questionTypes: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_QUESTION_TYPES_SUCCESS:
      return {
        ...state,
        questionTypes: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_QUESTION_TYPES_FAILURE:
      return {
        ...state,
        questionTypes: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case FETCH_FORM_CLEAR:
      return {
        ...state,
        fetchForm: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_FORM_REQUEST:
      return {
        ...state,
        fetchForm: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_FORM_SUCCESS:
      const formState = buildForm(action.payload.data.attributes, action.payload.data.id);
      return {
        ...state,
        fetchForm: {
          data: formState,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_FORM_FAILURE:
      return {
        ...state,
        fetchForm: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case UPDATE_FORM_ON_CLIENT:
      return {
        ...state,
        fetchForm: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case UPDATE_FORM_CLEAR:
      return {
        ...state,
        updateForm: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case UPDATE_FORM_REQUEST:
      return {
        ...state,
        updateForm: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case UPDATE_FORM_SUCCESS:
      return {
        ...state,
        updateForm: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case UPDATE_FORM_FAILURE:
      return {
        ...state,
        updateForm: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case UPDATE_FORM_NAME_CLEAR:
      return {
        ...state,
        updateFormName: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case UPDATE_FORM_NAME_REQUEST:
      return {
        ...state,
        updateFormName: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case UPDATE_FORM_NAME_SUCCESS:
      return {
        ...state,
        updateFormName: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case UPDATE_FORM_NAME_FAILURE:
      return {
        ...state,
        updateFormName: {
          data: null,
          loading: false,
          error: true,
          message: action.message,
        },
      };
    case UPDATE_FORM_STATUS_CLEAR:
      return {
        ...state,
        updateFormStatus: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case UPDATE_FORM_STATUS_REQUEST:
      return {
        ...state,
        updateFormStatus: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case UPDATE_FORM_STATUS_SUCCESS:
      return {
        ...state,
        updateFormStatus: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case UPDATE_BUILDER_FORM_STATUS_SUCCESS:
      const formData = { ...state.fetchForm };
      formData.data.status = action.payload.data[0].attributes.status;
      return {
        ...state,
        updateFormStatus: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.payload.message,
        },
        fetchForm: formData,
      };
    case UPDATE_FORM_STATUS_FAILURE:
      return {
        ...state,
        updateFormStatus: {
          data: null,
          loading: false,
          error: true,
          message: action.payload.message,
        },
      };
    case FAKE_FORM_UPDATE:
      return {
        ...state,
        updateFormStatus: {
          data: true,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case UPDATE_QUESTION_CLEAR:
      return {
        ...state,
        updateQuestion: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case UPDATE_QUESTION_REQUEST:
      return {
        ...state,
        updateQuestion: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        updateQuestion: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case UPDATE_QUESTION_FAILURE:
      return {
        ...state,
        updateQuestion: {
          data: null,
          loading: false,
          error: true,
          message: action.payload.message,
        },
      };
    case DUPLICATE_FORM_CLEAR:
      return {
        ...state,
        duplicateForm: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case DUPLICATE_FORM_REQUEST:
      return {
        ...state,
        duplicateForm: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case DUPLICATE_FORM_SUCCESS:
      return {
        ...state,
        duplicateForm: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.message,
        },
      };
    case DUPLICATE_FORM_FAILURE:
      return {
        ...state,
        duplicateForm: {
          data: null,
          loading: false,
          error: true,
          message: action.message,
        },
      };
    case DELETE_FORM_CLEAR:
      return {
        ...state,
        deleteForm: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case DELETE_FORM_REQUEST:
      return {
        ...state,
        deleteForm: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case DELETE_FORM_SUCCESS:
      return {
        ...state,
        deleteForm: {
          data: action.message,
          loading: false,
          error: false,
          message: action.message,
        },
      };
    case DELETE_FORM_FAILURE:
      return {
        ...state,
        deleteForm: {
          data: null,
          loading: false,
          error: true,
          message: action.message,
        },
      };
    case UPDATE_LAYOUT_CLEAR:
      return {
        ...state,
        updateLayout: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case UPDATE_LAYOUT_REQUEST:
      return {
        ...state,
        updateLayout: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case UPDATE_LAYOUT_SUCCESS:
      let updateForm = state.fetchForm.data;
      if (updateForm && action.payload.data) {
        updateForm.template_layout = {
          id: action.payload.data.id,
          layout: action.payload.data.attributes.layout,
        };

        const updateLayoutForm = buildForm(updateForm, updateForm.id);

        return {
          ...state,
          updateLayout: {
            data: action.payload.data,
            loading: false,
            error: false,
            message: action.message,
          },
          fetchForm: {
            data: updateLayoutForm,
            loading: false,
            error: false,
            errorMessage: null,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case UPDATE_LAYOUT_FAILURE:
      return {
        ...state,
        updateLayout: {
          data: null,
          loading: false,
          error: true,
          message: action.message,
        },
      };

    case DELETE_QUESTION_CLEAR:
      return {
        ...state,
        deleteQuestion: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case DELETE_QUESTION_REQUEST:
      return {
        ...state,
        deleteQuestion: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        deleteQuestion: {
          data: action.payload.template_layout,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case DELETE_QUESTION_FAILURE:
      return {
        ...state,
        deleteQuestion: {
          data: null,
          loading: false,
          error: true,
          message: action.payload.message,
        },
      };

    case FETCH_TRAVELER_SUBMISSION_CLEAR:
      return {
        ...state,
        travelerSubmission: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case FETCH_TRAVELER_SUBMISSION_REQUEST:
      return {
        ...state,
        travelerSubmission: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case FETCH_TRAVELER_SUBMISSION_SUCCESS:
      return {
        ...state,
        travelerSubmission: {
          data: buildTravelerSubmissionForm(action.payload.data.attributes, action.payload.data.id),
          requestToWithdrawData: action?.payload?.data?.attributes?.client_account?.request_to_withdraw_setting,
          submissionWithdrawalRequests: action?.payload?.data?.attributes?.submission_withdrawal_requests,
          submissionTags: action?.payload?.data?.attributes?.tags,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case FETCH_TRAVELER_SUBMISSION_FAILURE:
      return {
        ...state,
        travelerSubmission: {
          data: null,
          loading: false,
          error: true,
          message: action.payload.message,
        },
      };
    case PATCH_TRAVELER_FORM_CLEAR:
      return {
        ...state,
        patchTravelerForm: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case PATCH_TRAVELER_FORM_REQUEST:
      return {
        ...state,
        patchTravelerForm: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case PATCH_TRAVELER_FORM_SUCCESS:
      return {
        ...state,
        patchTravelerForm: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.message,
        },
      };
    case PATCH_TRAVELER_FORM_FAILURE:
      return {
        ...state,
        patchTravelerForm: {
          data: null,
          loading: false,
          error: true,
          message: action.message,
        },
      };
    case FETCH_SUBMISSION_STATUSES_CLEAR:
      return {
        ...state,
        submission_statuses: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_SUBMISSION_STATUSES_REQUEST:
      return {
        ...state,
        submission_statuses: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_SUBMISSION_STATUSES_SUCCESS:
      return {
        ...state,
        submission_statuses: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_SUBMISSION_STATUSES_FAILURE:
      return {
        ...state,
        submission_statuses: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case FETCH_VISITOR_SUBMISSION_CLEAR:
      return {
        ...state,
        visitorRecommendationSubmission: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_VISITOR_SUBMISSION_REQUEST:
      return {
        ...state,
        visitorRecommendationSubmission: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_VISITOR_SUBMISSION_SUCCESS:
      const recommendationSubmissionState = buildVisitorRecommendationSubmissionForm(
        action.payload.data.attributes,
        action.payload.data.id,
      );

      return {
        ...state,
        visitorRecommendationSubmission: {
          data: recommendationSubmissionState,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_VISITOR_SUBMISSION_FAILURE:
      return {
        ...state,
        visitorRecommendationSubmission: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case PATCH_VISITOR_SUBMISSION_CLEAR:
      return {
        ...state,
        patchVisitorRecommendationSubmission: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case PATCH_VISITOR_SUBMISSION_REQUEST:
      return {
        ...state,
        patchVisitorRecommendationSubmission: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case PATCH_VISITOR_SUBMISSION_SUCCESS:
      return {
        ...state,
        patchVisitorRecommendationSubmission: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case PATCH_VISITOR_SUBMISSION_FAILURE:
      return {
        ...state,
        patchVisitorRecommendationSubmission: {
          data: null,
          loading: false,
          error: true,
          message: action.payload.message,
        },
      };
    case FETCH_TRAVELER_ALTERNATE_INFO_REQUEST:
      return {
        ...state,
        alternateInfo: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_TRAVELER_ALTERNATE_INFO_SUCCESS:
      return {
        ...state,
        alternateInfo: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_TRAVELER_ALTERNATE_INFO_FAILURE:
      return {
        ...state,
        alternateInfo: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case FETCH_TRAVELER_ALTERNATE_INFO_CLEAR:
      return {
        ...state,
        alternateInfo: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case POST_SEND_RECOMMENDATION_MAILER_REQUEST:
      return {
        ...state,
        sendRecommendationMailer: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case POST_SEND_RECOMMENDATION_MAILER_SUCCESS:
      return {
        ...state,
        sendRecommendationMailer: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case POST_SEND_RECOMMENDATION_MAILER_FAILURE:
      return {
        ...state,
        sendRecommendationMailer: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case POST_SEND_RECOMMENDATION_MAILER_CLEAR:
      return {
        ...state,
        sendRecommendationMailer: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case CANCEL_SEND_RECOMMENDATION_MAILER_REQUEST:
      return {
        ...state,
        cancelRecommendationMailer: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case CANCEL_SEND_RECOMMENDATION_MAILER_SUCCESS:
      return {
        ...state,
        cancelRecommendationMailer: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case CANCEL_SEND_RECOMMENDATION_MAILER_FAILURE:
      return {
        ...state,
        cancelRecommendationMailer: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case CANCEL_SEND_RECOMMENDATION_MAILER_CLEAR:
      return {
        ...state,
        cancelRecommendationMailer: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case RESEND_RECOMMENDATION_MAILER_REQUEST:
      return {
        ...state,
        resendRecommendationMailer: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case RESEND_RECOMMENDATION_MAILER_SUCCESS:
      return {
        ...state,
        resendRecommendationMailer: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case RESEND_RECOMMENDATION_MAILER_FAILURE:
      return {
        ...state,
        resendRecommendationMailer: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case RESEND_RECOMMENDATION_MAILER_CLEAR:
      return {
        ...state,
        resendRecommendationMailer: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case UPDATE_FORM_SUBMISSION_RESPONSE:
      const travelerSubmissionData = { ...state.travelerSubmission };

      setTimeout(() => {
        const travelerSubmissionObj = { ...state.travelerSubmission };
        const updatedObject = { ...state.travelerSubmission };
        updatedObject?.data?.responses?.forEach(response => {
          if (response.question_id === action.payload.data.attributes.question_id) {
            response.uploaded_files = action.payload.data.attributes.uploaded_files;
          }
        });
      }, 0);
      return {
        ...state,
        travelerSubmission: updatedObject,
      };

    case UPLOAD_FILE_ADD_REQUEST:
      return {
        ...state,
        uploadFileAdd: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case UPLOAD_FILE_ADD_SUCCESS:
      return {
        ...state,
        uploadFileAdd: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case UPLOAD_FILE_ADD_FAILURE:
      return {
        ...state,
        uploadFileAdd: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case UPLOAD_FILE_ADD_CLEAR:
      return {
        ...state,
        uploadFileAdd: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case UPLOAD_FILE_DELETE_REQUEST:
      return {
        ...state,
        uploadFileDelete: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case UPLOAD_FILE_DELETE_SUCCESS:
      const payloadData = action.payload.data || {
        attributes: { question_id: action?.question_id, uploaded_files: [] },
      };

      return {
        ...state,
        uploadFileDelete: {
          data: payloadData,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case UPLOAD_FILE_DELETE_FAILURE:
      return {
        ...state,
        uploadFileDelete: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case UPLOAD_FILE_DELETE_CLEAR:
      return {
        ...state,
        uploadFileDelete: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case PAYMENT_STATUS_OPTIONS_CLEAR:
      return {
        ...state,
        paymentStatusOptions: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case PAYMENT_STATUS_OPTIONS_REQUEST:
      return {
        ...state,
        paymentStatusOptions: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case PAYMENT_STATUS_OPTIONS_SUCCESS:
      return {
        ...state,
        paymentStatusOptions: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case PAYMENT_STATUS_OPTIONS_FAILURE:
      return {
        ...state,
        paymentStatusOptions: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case PAYMENT_STATUS_FLYWIRE_UPDATE_ADMIN:
    // eslint-disable-next-line no-case-declarations, no-fallthrough
    case PAYMENT_STATUS_FLYWIRE_UPDATE:
      return {
        ...state,
        travelerSubmission: {
          data: updatePaymentLogs({ ...state.travelerSubmission.data }, action.payload),
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case UPDATE_ALL_PAYMENTS_STATUS_ADMIN:
      return {
        ...state,
        travelerSubmission: {
          data: updateAllPaymentStatus({ ...state.travelerSubmission.data }, action.payload),
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_TRAVELER_INFO_FIELDS_CLEAR:
      return {
        ...state,
        travelerInfoFields: {
          data: null,
          custom_fields: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_TRAVELER_INFO_FIELDS_REQUEST:
      return {
        ...state,
        travelerInfoFields: {
          data: null,
          custom_fields: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_TRAVELER_INFO_SUCCESS:
      return {
        ...state,
        travelerInfoFields: {
          data: action.payload.data,
          custom_fields: action.payload.custom_fields,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_TRAVELER_INFO_FIELDS_FAILURE:
      return {
        ...state,
        travelerInfoFields: {
          data: null,
          custom_fields: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case ADMIN_ADD_FORMS_REQUEST:
      return {
        ...state,
        adminAddForms: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case ADMIN_ADD_FORMS_SUCCESS:
      return {
        ...state,
        adminAddForms: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case ADMIN_ADD_FORMS_FAILURE:
      return {
        ...state,
        adminAddForms: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case ADMIN_ADD_FORMS_CLEAR:
      return {
        ...state,
        adminAddForms: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case DELETE_TRAVELER_APPLICATION_REQUEST:
      return {
        ...state,
        deleteTravelerApplication: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case DELETE_TRAVELER_APPLICATION_SUCCESS:
      return {
        ...state,
        deleteTravelerApplication: {
          data: action.message,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case DELETE_TRAVELER_APPLICATION_FAILURE:
      return {
        ...state,
        deleteTravelerApplication: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.message,
        },
      };
    case DELETE_TRAVELER_APPLICATION_CLEAR:
      return {
        ...state,
        deleteTravelerApplication: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case ADD_FORMS_TO_TRAVELER_PROFILE_REQUEST:
      return {
        ...state,
        addFormsProfile: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case ADD_FORMS_TO_TRAVELER_PROFILE_SUCCESS:
      return {
        ...state,
        addFormsProfile: {
          data: action.payload.data,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case ADD_FORMS_TO_TRAVELER_PROFILE_FAILURE:
      return {
        ...state,
        addFormsProfile: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload.message,
        },
      };
    case ADD_FORMS_TO_TRAVELER_PROFILE_CLEAR:
      return {
        ...state,
        addFormsProfile: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case ADD_APPLICATION_TO_TRAVELER_PROFILE_REQUEST:
      return {
        ...state,
        addApplicationProfile: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case ADD_APPLICATION_TO_TRAVELER_PROFILE_SUCCESS:
      return {
        ...state,
        addApplicationProfile: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case ADD_APPLICATION_TO_TRAVELER_PROFILE_FAILURE:
      return {
        ...state,
        addApplicationProfile: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case ADD_APPLICATION_TO_TRAVELER_PROFILE_CLEAR:
      return {
        ...state,
        addApplicationProfile: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case GET_AVAILABLE_FORMS_REQUEST:
      return {
        ...state,
        availableForms: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case GET_AVAILABLE_FORMS_SUCCESS:
      return {
        ...state,
        availableForms: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case GET_AVAILABLE_FORMS_FAILURE:
      return {
        ...state,
        availableForms: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case GET_AVAILABLE_FORMS_CLEAR:
      return {
        ...state,
        availableForms: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case SET_TRANSFER_APPLICATION_SUCCESS:
      return {
        ...state,
        transferApplication: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case SET_TRANSFER_APPLICATION_ERROR:
      return {
        ...state,
        transferApplication: {
          data: action.payload,
          loading: false,
          error: true,
          errorMessage: null,
        },
      };

    case FETCH_AVAILABLE_APPLICATIONS_REQUEST:
      return {
        ...state,
        availableApplications: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_AVAILABLE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        availableApplications: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_AVAILABLE_APPLICATIONS_FAILURE:
      return {
        ...state,
        availableApplications: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case FETCH_AVAILABLE_APPLICATIONS_CLEAR:
      return {
        ...state,
        availableApplications: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case FETCH_AVAILABLE_ALTERNATES_REQUEST:
      return {
        ...state,
        availableAlternates: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_AVAILABLE_ALTERNATES_SUCCESS:
      return {
        ...state,
        availableAlternates: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_AVAILABLE_ALTERNATES_FAILURE:
      return {
        ...state,
        availableAlternates: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case FETCH_AVAILABLE_ALTERNATES_CLEAR:
      return {
        ...state,
        availableAlternates: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case REQUEST_TO_WITHDRAW_REQUEST:
      return {
        ...state,
        requestToWithdraw: {
          ...state.requestToWithdraw,
          loading: true,
          error: '',
        },
      };

    case REQUEST_TO_WITHDRAW_SUCCESS:
      return {
        ...state,
        requestToWithdraw: {
          ...state.requestToWithdraw,
          loading: false,
        },
      };

    case REQUEST_TO_WITHDRAW_FAILURE:
      return {
        ...state,
        requestToWithdraw: {
          ...state.requestToWithdraw,
          loading: false,
          error: action.payload,
        },
      };

    case RESPOND_REQUEST_TO_WITHDRAW_REQUEST:
      return {
        ...state,
        respondRequestToWithdraw: {
          ...state.respondRequestToWithdraw,
          loading: true,
          error: '',
        },
      };

    case RESPOND_REQUEST_TO_WITHDRAW_SUCCESS:
      return {
        ...state,
        respondRequestToWithdraw: {
          ...state.respondRequestToWithdraw,
          loading: false,
        },
      };

    case RESPOND_REQUEST_TO_WITHDRAW_FAILURE:
      return {
        ...state,
        respondRequestToWithdraw: {
          ...state.respondRequestToWithdraw,
          loading: false,
          error: action.payload,
        },
      };

    case FETCH_AVAILABLE_TAG_REQUEST:
      return {
        ...state,
        availableTags: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_AVAILABLE_TAG_SUCCESS:
      return {
        ...state,
        availableTags: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_AVAILABLE_TAG_FAILURE:
      return {
        ...state,
        availableTags: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case FETCH_AVAILABLE_TAG_CLEAR:
      return {
        ...state,
        availableTags: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case FETCH_TAG_COLORS_REQUEST:
      return {
        ...state,
        tagColors: {
          data: null,
          loading: true,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_TAG_COLORS_SUCCESS:
      return {
        ...state,
        tagColors: {
          data: action.payload,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };
    case FETCH_TAG_COLORS_FAILURE:
      return {
        ...state,
        tagColors: {
          data: null,
          loading: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case FETCH_TAG_COLORS_CLEAR:
      return {
        ...state,
        tagColors: {
          data: null,
          loading: false,
          error: false,
          errorMessage: null,
        },
      };

    case ATTACH_TAG_CLEAR:
      return {
        ...state,
        attachTag: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case ATTACH_TAG_REQUEST:
      return {
        ...state,
        attachTag: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case ATTACH_TAG_SUCCESS:
      return {
        ...state,
        attachTag: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case ATTACH_TAG_FAILURE:
      return {
        ...state,
        attachTag: {
          data: null,
          loading: false,
          error: true,
          message: action.message,
        },
      };

    case DELETE_SUBMISSION_TAG_REQUEST:
      return {
        ...state,
        deleteTag: {
          ...state.deleteTag,
          loading: true,
          error: '',
        },
      };

    case DELETE_SUBMISSION_TAG_SUCCESS:
      return {
        ...state,
        deleteTag: {
          ...state.deleteTag,
          loading: false,
          error: '',
        },
      };

    case DELETE_SUBMISSION_TAG_FAILURE:
      return {
        ...state,
        deleteTag: {
          ...state.deleteTag,
          loading: false,
          error: action.payload,
        },
      };

    case CREATE_SUBMISSION_TAG_CLEAR:
      return {
        ...state,
        submissionTag: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case CREATE_SUBMISSION_TAG_REQUEST:
      return {
        ...state,
        submissionTag: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case CREATE_SUBMISSION_TAG_SUCCESS:
      return {
        ...state,
        submissionTag: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case CREATE_SUBMISSION_TAG_FAILURE:
      return {
        ...state,
        submissionTag: {
          data: null,
          loading: false,
          error: true,
          message: action.message,
        },
      };

    case UPDATE_SUBMISSION_TAG_CLEAR:
      return {
        ...state,
        submissionTag: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case UPDATE_SUBMISSION_TAG_REQUEST:
      return {
        ...state,
        submissionTag: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case UPDATE_SUBMISSION_TAG_SUCCESS:
      return {
        ...state,
        submissionTag: {
          data: action.payload.data,
          loading: false,
          error: false,
          message: action.payload.message,
        },
      };
    case UPDATE_SUBMISSION_TAG_FAILURE:
      return {
        ...state,
        submissionTag: {
          data: null,
          loading: false,
          error: true,
          message: action.message,
        },
      };

    case GET_TOKENS_REQUEST:
      return {
        ...state,
        token: {
          data: [],
          loading: true,
          error: '',
        },
      };

    case GET_TOKENS_SUCCESS:
      return {
        ...state,
        token: {
          data: action.payload,
          loading: false,
          error: '',
        },
      };

    case GET_TOKENS_FAILURE:
      return {
        ...state,
        token: {
          data: [],
          loading: false,
          error: action.payload,
        },
      };

    case BATCH_ZIP_DOWNLOAD_REQUEST:
      return {
        ...state,
        batchZipDownload: {
          loading: true,
          data: null,
          error: '',
        },
      };

    case BATCH_ZIP_DOWNLOAD_SUCCESS:
      return {
        ...state,
        batchZipDownload: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case BATCH_ZIP_DOWNLOAD_FAILURE:
      return {
        ...state,
        batchZipDownload: {
          loading: false,
          data: null,
          error: action.payload,
        },
      };

    case BATCH_MANUAL_DATA_SYNC_REQUEST:
      return {
        ...state,
        batchManualDataSync: {
          loading: true,
          data: null,
          error: '',
        },
      };

    case BATCH_MANUAL_DATA_SYNC_SUCCESS:
      return {
        ...state,
        batchManualDataSync: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case BATCH_MANUAL_DATA_SYNC_FAILURE:
      return {
        ...state,
        batchManualDataSync: {
          loading: false,
          data: null,
          error: action.payload,
        },
      };

    case FETCH_WITHDRAW_DEFFERED_REASON:
      return {
        ...state,
        withdrawReason: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case ADMIN_ADD_PLAN_FORM_TO_PLAN_REQUEST:
      return {
        ...state,
        addPlanFormsToPlans: {
          loading: true,
          data: null,
          error: '',
        },
      };

    case ADMIN_ADD_PLAN_FORM_TO_PLAN_SUCCESS:
      return {
        ...state,
        addPlanFormsToPlans: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case ADMIN_ADD_PLAN_FORM_TO_PLAN_FAILURE:
      return {
        ...state,
        addPlanFormsToPlans: {
          loading: false,
          data: null,
          error: action.payload,
        },
      };

    case ADMIN_UPDATE_PLAN_FORM_TO_PLAN_REQUEST:
      return {
        ...state,
        updatePlanFormsToPlans: {
          loading: true,
          data: null,
          error: '',
        },
      };

    case ADMIN_UPDATE_PLAN_FORM_TO_PLAN_SUCCESS:
      return {
        ...state,
        updatePlanFormsToPlans: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case ADMIN_UPDATE_PLAN_FORM_TO_PLAN_FAILURE:
      return {
        ...state,
        updatePlanFormsToPlans: {
          loading: false,
          data: null,
          error: action.payload,
        },
      };

    case ADMIN_DELETE_PLAN_FORM_TO_PLAN_REQUEST:
      return {
        ...state,
        deletePlanFormsToPlans: {
          loading: true,
          data: null,
          error: '',
        },
      };

    case ADMIN_DELETE_PLAN_FORM_TO_PLAN_SUCCESS:
      return {
        ...state,
        deletePlanFormsToPlans: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case ADMIN_DELETE_PLAN_FORM_TO_PLAN_FAILURE:
      return {
        ...state,
        deletePlanFormsToPlans: {
          loading: false,
          data: null,
          error: action.payload,
        },
      };

    case ADD_PLAN_FORMS_TO_TRAVELERS_REQUEST:
      return {
        ...state,
        addPlanFormsToTravelers: {
          loading: true,
          data: null,
          error: '',
        },
      };

    case ADD_PLAN_FORMS_TO_TRAVELERS_SUCCESS:
      return {
        ...state,
        addPlanFormsToTravelers: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case ADD_PLAN_FORMS_TO_TRAVELERS_FAILURE:
      return {
        ...state,
        addPlanFormsToTravelers: {
          loading: false,
          data: null,
          error: action.payload,
        },
      };
    case FETCH_RECOMMENDATION_SUBMISSIONS_CLEAR:
      return {
        ...state,
        recommendationSubmissions: {
          data: null,
          loading: false,
          error: false,
          message: null,
        },
      };
    case FETCH_RECOMMENDATION_SUBMISSIONS_REQUEST:
      return {
        ...state,
        recommendationSubmissions: {
          data: null,
          loading: true,
          error: false,
          message: null,
        },
      };
    case FETCH_RECOMMENDATION_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        recommendationSubmissions: {
          data: action.payload,
          loading: false,
          error: false,
          message: action?.payload?.message,
        },
      };
    case FETCH_RECOMMENDATION_SUBMISSIONS_FAILURE:
      return {
        ...state,
        recommendationSubmissions: {
          data: null,
          loading: false,
          error: true,
          message: action?.payload?.message,
        },
      };
    default:
      return state;
  }
}

const buildForm = (form, id) => {
  let qLayout = [];

  form.template_layout.layout.forEach(layout => {
    const q = form.questions?.find(q => q.question_id?.toString() === layout.id?.toString());

    if (q && layout) {
      qLayout.push({
        layout_id: layout.id,
        layout_position: layout.position,
        questionId: q.question_id,
        question: q,
      });
    }
  });

  const newForm = {
    id: id,
    created_at: form.created_at,
    default: form.default,
    layout: form.template_layout,
    name: form.name,
    questions: form.questions,
    renderedLayout: qLayout,
    status: form.status,
    allow_proxy_submission: form.allow_proxy_submission,
    template_type_name: form.template_type_name,
  };

  return newForm;
};

const buildTravelerSubmissionForm = (form, id) => {
  let qLayout = [];

  form.template.template_layout.layout.forEach(layout => {
    const q = form.template.questions.find(q => q.question_id?.toString() === layout.id?.toString());

    if (q && layout) {
      const findResponse = form.responses.find(r => r.question_id.toString() === q.question_id.toString());

      qLayout.push({
        layout_id: layout.id,
        layout_position: layout.position,
        questionId: q.question_id,
        question: q,
        response: findResponse ? findResponse : null,
        cash_net_codes: q.question_type_identifier === 'cash_net_with_code' ? form.cash_net_codes : [],
      });
    }

    if (q?.single_choice_question_logic?.child_question) {
      const child_q = q?.single_choice_question_logic?.child_question;
      const findResponse = form?.responses.find(r => r?.question_id?.toString() === child_q?.question_id?.toString());

      child_q.response = findResponse;

      qLayout.push({
        layout_id: child_q?.question_id?.toString(),
        layout_position: ['9999', '9999'],
        questionId: child_q.question_id,
        question: child_q,
        response: findResponse ? findResponse : null,
        cash_net_codes: child_q.question_type_identifier === 'cash_net_with_code' ? form.cash_net_codes : [],
      });
    }

    if (q?.dropdown_question_logic?.child_question) {
      const child_q = q?.dropdown_question_logic?.child_question;
      const findResponse = form?.responses.find(r => r?.question_id?.toString() === child_q?.question_id?.toString());

      child_q.response = findResponse;

      qLayout.push({
        layout_id: child_q?.question_id?.toString(),
        layout_position: ['9999', '9999'],
        questionId: child_q.question_id,
        question: child_q,
        response: findResponse ? findResponse : null,
        cash_net_codes: child_q.question_type_identifier === 'cash_net_with_code' ? form.cash_net_codes : [],
      });
    }
  });

  const newForm = {
    alternate_program_ranges: form.alternate_program_ranges,
    deadline: form.deadline,
    application_id: form?.application_id,
    created_at: form.created_at,
    submitted_at: form?.submitted_at,
    id: id,
    layout: form.template.template_layout,
    program_range: form.program_range,
    questions: form.template.questions,
    renderedLayout: qLayout,
    responses: form.responses,
    corresponding_forms: form.corresponding_forms,
    submission_status_change_logs: form.submission_status_change_logs,
    submission_review: form.submission_review || {},
    submission_status: form.submission_status,
    submission_tracking_step: form.submission_tracking_step,
    submission_type: form.submission_type,
    templateName: form.template.name,
    planInfo: form.plan,
    user: form.user,
  };

  return newForm;
};

const updateAllPaymentStatus = (submissionData, paymentStatusUpdates) => {
  if (!submissionData.responses) {
    return submissionData;
  }

  const data = _cloneDeep(submissionData);

  data.responses = data.responses.map(response => {
    const paymentIdx = paymentStatusUpdates.findIndex(upd => upd.question_id === response?.question_id);
    return paymentStatusUpdates[paymentIdx] || response;
  });

  data.renderedLayout = data.renderedLayout.map(layout => {
    const idx = paymentStatusUpdates.findIndex(upd => upd.question_id === layout?.questionId);
    return Object.assign(layout, { response: paymentStatusUpdates[idx] || layout });
  }); /// ADD .response

  return data;
};

const updatePaymentLogs = (data, paymentUpdate) => {
  const responseIdx = data.responses.findIndex(resp => resp.id === paymentUpdate.payment_response_id);
  const questionId = data.responses[responseIdx].question_id;
  const questionIdx = data.renderedLayout.findIndex(q => q.questionId === questionId);
  //update state values for responses
  data.responses[responseIdx].payment_response_status_change_logs.unshift(paymentUpdate);
  data.responses[responseIdx].payment_status_id = paymentUpdate.payment_status_id;
  data.responses[responseIdx].payment_status_identifier = paymentUpdate.status;
  data.responses[responseIdx].payment_status_name = paymentUpdate.payment_status_name;

  //update form display data
  data.renderedLayout[questionIdx].response.payment_status_id = paymentUpdate.payment_status_id;
  data.renderedLayout[questionIdx].response.payment_response_status_change_logs =
    data.responses[responseIdx].payment_response_status_change_logs;

  return data;
};

const buildVisitorRecommendationSubmissionForm = (form, id) => {
  let qLayout = [];
  let qChildLayout = [];
  form.template.template_layout.layout.forEach(layout => {
    const q = form.template.questions.find(q => q.question_id.toString() === layout.id);

    if (q && layout) {
      const findResponse = form.responses.find(r => r.question_id.toString() === q.question_id.toString());
      qLayout.push({
        layout_id: layout.id,
        layout_position: layout.position,
        questionId: q.question_id,
        question: q,
        response: findResponse ? findResponse : null,
      });

      if (q.single_choice_question_logic?.child_question?.question_id) {
        const findResponseChild = form.responses.find(
          r => r.question_id.toString() === q.single_choice_question_logic.child_question.question_id.toString(),
        );
        qChildLayout.push({
          layout_id: q.single_choice_question_logic.child_question.question_id,
          layout_position: layout.position,
          questionId: q.single_choice_question_logic.child_question.question_id,
          question: q.single_choice_question_logic.child_question,
          response: findResponseChild ? findResponseChild : null,
        });
      }

      if (q?.dropdown_question_logic?.child_question) {
        const child_q = q?.dropdown_question_logic?.child_question;
        const findResponse = form?.responses.find(r => r?.question_id?.toString() === child_q?.question_id?.toString());

        child_q.response = findResponse;

        qLayout.push({
          layout_id: child_q?.question_id?.toString(),
          layout_position: ['9999', '9999'],
          questionId: child_q.question_id,
          question: child_q,
          response: findResponse ? findResponse : null,
          cash_net_codes: child_q.question_type_identifier === 'cash_net_with_code' ? form.cash_net_codes : [],
        });
      }
    }
  });

  const newForm = {
    alternate_program_ranges: form.alternate_program_ranges,
    client_account: form.client_account,
    deadline: form.deadline,
    id: id,
    layout: form.template.template_layout,
    program_range: form.program_range,
    questions: form.template.questions,
    renderedLayout: qLayout,
    renderedChildLayout: qChildLayout,
    responses: form.responses,
    instructions: form.recommender_instructions,
    submission_review: form.submission_review || {},
    submission_status: form.submission_status,
    submission_tracking_step: form.submission_tracking_step,
    submission_type: form.submission_type,
    template: form.template,
    traveler: form.traveler,
  };

  return newForm;
};
