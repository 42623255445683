import {
  FETCH_CURRENT_USER,
  SET_CURRENT_USER_LOADING,
  FETCH_PROFILE_DATA,
  UPDATE_PROFILE_DATA,
  FETCH_TRAVELER_PREFERENCES,
  FETCH_TRAVELER_DETAILS,
  FETCH_TRAVELER_NOTES,
  FETCH_TRAVELER_MESSAGES,
  FETCH_TRAVELER_MESSAGES_REQUEST,
  FETCH_TRAVELER_MESSAGES_FAILURE,
  CREATE_TRAVELER_MESSAGE,
  CREATE_TRAVELER_MESSAGE_LOADING,
  CHECK_TRAVELER_MESSAGE,
  FETCH_ASSIGNED_ADMINS,
  UPDATE_ASSIGNED_ADMINS,
  FETCH_TRAVELER_HEADER,
  FETCH_TRAVELER_INSIGHT,
  SELECT_TRAVELER_PROFILE_TAB,
  SELECT_MESSAGE_TAB,
  CREATE_TRAVELER_NOTE,
  RESET_PROFILE_CODE,
  FETCH_NEW_MESSAGES,
  FETCH_NEW_MESSAGES_TRAVELER,
  RESET_MESSAGE_CREATE_CODE,
  UPDATE_STATUS_APPLICATION,
  ERROR_STATUS_APPLICATION,
  UPDATE_STATUS_FORM,
  ERROR_STATUS_FORM,
  ADD_NEW_FORM,
  FETCH_GENDER_IDENTITIES,
  MANUAL_SYNC_TRAVELER,
  CLEAR_MANUAL_SYNC_TRAVELER,
  DELETE_TRAVELER_REQUEST,
  DELETE_TRAVELER_SUCCESS,
  DELETE_TRAVELER_CLEAR,
  DELETE_TRAVELER_FAILURE,
  CHECK_USER_FAVORITE_DISCLAIMER,
  MARK_ALL_READ_MESSAGE_SUCCESS,
  MARK_ALL_READ_MESSAGE_REQUEST,
  MARK_ALL_READ_MESSAGE_FAILURE,
  TOGGLE_PROGRAM_FAVORITE,
  FETCH_TERM_POLICY_REQUEST,
  FETCH_TERM_POLICY_SUCCESS,
  FETCH_TERM_POLICY_FAILURE,
  ACCEPT_TERM_REQUEST,
  ACCEPT_TERM_SUCCESS,
  ACCEPT_TERM_FAILURE,
} from '../actions/types';
import _cloneDeep from 'lodash/cloneDeep';
import _map from 'lodash/map';

const initialState = {
  currentUserLoading: false,
  profileLoading: true,
  preferencesLoading: true,
  detailsLoading: true,
  notesLoading: true,
  headerLoading: true,
  messagesLoading: true,
  userLoading: true,
  newMessagesLoading: true,
  currentUser: {},
  avatar: {},
  permissions: {},
  profile: [],
  header: [],
  travelerInsight: [],
  preferences: [],
  applications: [],
  favorites: [],
  forms: [],
  notes: [],
  messages: [],
  newMessages: [],
  admins: [],
  assignedAdmins: [],
  messageRead: null,
  events: [],
  activeTab: 'messages',
  activeMessageTab: 'messages',
  updateProfileStatus: null,
  messageCreate: null,
  noteCreate: null,
  errorUpdateStatus: '',
  errorUpdateFormStatus: '',
  freemium: false,
  clientAccountState: '',
  lead: false,
  logout_url: '',
  clientFeatureList: {},
  customAliases: {
    alias_program: 'Program',
    alias_programs: 'Programs',
    alias_traveler: 'Traveler',
    alias_travelers: 'Travelers',
    alias_traveling: 'Traveling',
  },
  customBranding: {
    themes: {
      theme_color_accent: '#DD602A',
      theme_color_dark: '#00277C',
      theme_color_light: '#65A5E0',
    },
    logo: {
      theme_logo: '/uploads/client_account_info/logo/62/client_logo.jpg',
    },
  },
  safecheck: '',
  safecheckStatus: '',
  show_intake: null,
  show_program_match: null,
  show_traveler_welcome_screen: null,
  allow_traveler_defer_application: true,
  intake_complete: null,
  program_match_complete: null,
  manualSync: null,
  deleteTraveler: {
    data: null,
    loading: true,
    error: null,
  },
  role: '',
  program_favorite_disclaimer_accepted: false,
  messageLoading: false,
  messageError: '',
  createMessageLoading: false,
  favoriteProgram: {
    flag: false,
    programId: '',
  },
  termsOfUse: {
    id: '',
    type: '',
    term_of_services: '',
    client_account_id: '',
    privacy_policy: '',
    loading: false,
    error: '',
  },
  acceptTerms: {
    loading: false,
    error: '',
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER_LOADING:
      return {
        ...state,
        currentUserLoading: action.payload,
      };

    case FETCH_CURRENT_USER:
      /*
       * For updates to make an admin a traveler, overriding the
       *  client_user_role? and is_traveler? values.
       *  They will be forced to the admin_sign_in value.
       * This is temp fix until all places in code can be updated to remove
       *  client_user_role? and is_traveler? usage.
       */
      let setUser = action.payload.user;
      setUser['client_user_role?'] = setUser.admin_sign_in;
      setUser['is_traveler?'] = !setUser.admin_sign_in;
      /* ----- */

      let userRole = setUser.admin_sign_in ? 'admin' : 'traveler';

      return {
        ...state,
        currentUser: setUser,
        avatar: action.payload.avatar,
        permissions: action.payload.permissions,
        customBranding: action.payload.branding_theme,
        profileInfo: action.payload.profile_info,
        customAliases: action.payload.use_custom_aliases ? action.payload.custom_aliases : state.customAliases,
        safeCheckCustomMessage: action.payload.client_account_safe_check_custom_text,
        freemium: action.payload.freemium,
        clientAccountState: action.payload.client_account_state,
        clientAccountName: action.payload.client_account_name,
        lead: action.payload.lead,
        orgTimezone: action.payload.org_timezone,
        other_admin_limit: action.payload.other_admin_limit,
        logout_url: action.payload.logout_url || '',
        clientFeatureList: action.payload.client_feature_list,
        userLoading: false,
        safecheck: action.payload.safe_check_phone_number,
        safecheckStatus: action.payload.safe_check_enrollment_status,
        show_intake: action.payload.show_intake,
        show_program_match: action.payload.show_program_match,
        show_traveler_welcome_screen: action.payload.show_traveler_welcome_screen,
        super_user_limit: action.payload.super_user_limit,
        allow_traveler_defer_application: action.payload.allow_traveler_deferral,
        admin_access: action.payload.admin_access,
        admin_sign_in: action.payload.admin_sign_in,
        intake_complete: action.payload.intake_complete,
        program_match_complete: action.payload.program_match_complete,
        role: action.payload.role,
        standard_report_notification_read: action.payload.standard_report_notification_read,
        program_favorite_disclaimer_accepted: action.payload.program_favorite_disclaimer_accepted,
        proxy_management: action.payload.client_feature_list.proxy_management,
        is_proxy_user: action.payload.user.is_proxy_user,
        is_authorized_traveler: action.payload.user.is_authorized_traveler,
        proxy_user_id: action.payload.user.proxy_user_id,
        user_role: userRole,
        proxy_user: action.payload.proxy_user,
      };
    case CHECK_USER_FAVORITE_DISCLAIMER:
      return {
        ...state,
        program_favorite_disclaimer_accepted: action.payload,
      };
    case FETCH_PROFILE_DATA:
      return {
        ...state,
        profile: action.payload,
        profileLoading: false,
      };
    case FETCH_TRAVELER_DETAILS:
      return {
        ...state,
        applications: action.payload.applications,
        favorites: action.payload.favorite_programs,
        forms: action.payload.forms,
        events: action.payload.events,
        detailsLoading: false,
      };
    case UPDATE_PROFILE_DATA:
      return {
        ...state,
        updateProfileStatus: action.success,
        profile: action.payload,
      };
    case FETCH_TRAVELER_HEADER:
      return {
        ...state,
        header: action.payload,
        headerLoading: false,
      };
    case FETCH_TRAVELER_INSIGHT:
      return {
        ...state,
        travelerInsight: action.payload,
        headerLoading: false,
      };
    case FETCH_TRAVELER_PREFERENCES:
      return {
        ...state,
        preferences: action.payload,
        preferencesLoading: false,
      };
    case FETCH_TRAVELER_NOTES:
      return {
        ...state,
        notes: action.payload,
        noteCreate: null,
        notesLoading: false,
      };
    case CREATE_TRAVELER_NOTE:
      return {
        ...state,
        note: action.payload,
        noteCreate: action.payload.status,
        notes: [action.payload.data, ...state.notes.data],
        notesLoading: false,
      };

    case FETCH_TRAVELER_MESSAGES_REQUEST:
      return {
        ...state,
        messageLoading: true,
      };

    case FETCH_TRAVELER_MESSAGES:
      return {
        ...state,
        messages: action.payload.messages,
        messageCreate: null,
        assignedAdmins: action.payload.assigned_admins,
        admins: action.payload.available_admins,
        messagesLoading: false,
        currentUserAvatar: action.payload.current_user_avatar,
        messageLoading: false,
      };

    case FETCH_TRAVELER_MESSAGES_FAILURE:
      return {
        ...state,
        messageLoading: false,
        messageError: action.payload,
      };

    case MARK_ALL_READ_MESSAGE_REQUEST:
      return {
        ...state,
        messageLoading: true,
      };

    case MARK_ALL_READ_MESSAGE_SUCCESS:
      return {
        ...state,
        messageLoading: false,
      };

    case MARK_ALL_READ_MESSAGE_FAILURE:
      return {
        ...state,
        messageLoading: false,
        messageError: action.payload,
      };

    case CREATE_TRAVELER_MESSAGE:
      return {
        ...state,
        messageCreate: action.payload.status,
      };

    case CREATE_TRAVELER_MESSAGE_LOADING:
      return {
        ...state,
        createMessageLoading: action.payload,
      };

    case CHECK_TRAVELER_MESSAGE:
      const existingAllMessages = [...state.messages];
      existingAllMessages.filter(msg => {
        if (msg.message_id === action.payload.id) {
          msg.is_read = true;
          return true;
        }
      });
      return {
        ...state,
        messageRead: action.success,
        messagesLoading: false,
        messages: existingAllMessages,
      };
    case UPDATE_ASSIGNED_ADMINS:
      return {
        ...state,
        assignedAdmins: action.payload,
        adminsLoading: false,
      };
    case SELECT_TRAVELER_PROFILE_TAB:
      return {
        ...state,
        activeTab: action.tab,
        updateProfileStatus: null,
      };
    case SELECT_MESSAGE_TAB:
      return {
        ...state,
        activeMessageTab: action.tab,
      };
    case RESET_PROFILE_CODE:
      return {
        ...state,
        updateProfileStatus: false,
      };
    case RESET_MESSAGE_CREATE_CODE:
      return {
        ...state,
        messageCreate: null,
      };
    case FETCH_NEW_MESSAGES:
      return {
        ...state,
        newMessages: action.payload,
        newMessagesLoading: false,
      };
    case FETCH_NEW_MESSAGES_TRAVELER:
      return {
        ...state,
        newMessages: action.payload,
        newMessagesLoading: false,
      };
    case UPDATE_STATUS_APPLICATION:
      let change_applications = _map(state.applications, application => {
        if (application.application_id === action.payload.traveler_application.id) {
          application.tapp_status = action.payload.traveler_application.status;
          return application;
        } else {
          return application;
        }
      });
      return {
        ...state,
        applications: change_applications,
        errorUpdateStatus: '',
      };
    case ERROR_STATUS_APPLICATION:
      return {
        ...state,
        errorUpdateStatus: action.payload,
      };
    case UPDATE_STATUS_FORM:
      let change_forms = _map(state.forms, form => {
        if (form.form_id === action.payload.traveler_form.id) {
          form.status = action.payload.traveler_form.status;
          return form;
        } else {
          return form;
        }
      });
      return {
        ...state,
        forms: change_forms,
        errorUpdateFormStatus: '',
      };
    case ERROR_STATUS_FORM:
      return {
        ...state,
        errorUpdateFormStatus: action.payload,
      };
    case ADD_NEW_FORM:
      let forms = _cloneDeep(state.forms);
      forms.push(action.payload);
      return {
        ...state,
        forms: forms,
      };
    case FETCH_GENDER_IDENTITIES:
      return {
        ...state,
        allGenderIdentities: action.payload.data,
      };
    case MANUAL_SYNC_TRAVELER:
      return {
        ...state,
        manualSync: action.payload,
      };
    case CLEAR_MANUAL_SYNC_TRAVELER:
      return {
        ...state,
        manualSync: null,
      };
    case DELETE_TRAVELER_REQUEST:
      return {
        ...state,
        deleteTraveler: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case DELETE_TRAVELER_SUCCESS:
      return {
        ...state,
        deleteTraveler: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case DELETE_TRAVELER_FAILURE:
      return {
        ...state,
        deleteTraveler: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_TRAVELER_CLEAR:
      return {
        ...state,
        deleteTraveler: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case TOGGLE_PROGRAM_FAVORITE:
      return {
        ...state,
        favoriteProgram: {
          flag: action.payload.flag,
          programId: action.payload.programId,
        },
      };

    case FETCH_TERM_POLICY_REQUEST:
      return {
        ...state,
        termsOfUse: {
          ...state.termsOfUse,
          loading: true,
        },
      };

    case FETCH_TERM_POLICY_SUCCESS:
      return {
        ...state,
        termsOfUse: {
          ...state.termsOfUse,
          loading: false,
          id: action.payload.id,
          type: action.payload.type,
          term_of_services: action.payload.term_of_services,
          client_account_id: action.payload.client_account_id,
          privacy_policy: action.payload.privacy_policy,
        },
      };

    case FETCH_TERM_POLICY_FAILURE:
      return {
        ...state,
        termsOfUse: {
          ...state.termsOfUse,
          loading: false,
          error: action.payload,
        },
      };

    case ACCEPT_TERM_REQUEST:
      return {
        ...state,
        acceptTerms: {
          ...state.acceptTerms,
          loading: true,
        },
      };

    case ACCEPT_TERM_SUCCESS:
      return {
        ...state,
        acceptTerms: {
          loading: false,
          error: '',
        },
      };

    case ACCEPT_TERM_FAILURE:
      return {
        ...state,
        acceptTerms: {
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
}
